import { has } from "lodash";

import { RelationRef, RichText } from "@api";

import * as md from "./markdown";
import { Maybe, when } from "./maybe";
import { maybeValues } from "./object";

const trimHtml = (html: Maybe<string>) =>
  html?.trim()?.replace("<p></p>", "")?.replace("<p> </p>", "") || "";

const trimMarkdown = (md: Maybe<string>) => md?.trim() || "";

export const hasValue = (rt: Maybe<RichText>) =>
  !!rt && !!(trimHtml(rt.html) || trimMarkdown(rt.markdown) || rt.text?.trim());

export const isEqual = (a: Maybe<RichText>, b: Maybe<RichText>) => {
  if (a === b) return true;
  if (!a || !b) return false;

  if (has(a, "html") && has(b, "html")) {
    return trimHtml(a.html) === trimHtml(b.html);
  }

  if (has(a, "markdown") && has(b, "markdown")) {
    return trimMarkdown(a.markdown) === trimMarkdown(b.markdown);
  }

  if (has(a, "text") && has(b, "text")) {
    return a.text === b.text;
  }

  if (!hasValue(a) && !hasValue(b)) {
    return true;
  }

  return false;
};

export const isMeaningfulChange = (a: Maybe<RichText>, b: Maybe<RichText>) =>
  !isEqual(a, b) && (hasValue(a) || hasValue(b));

export const isRichText = <T>(rt: Maybe<T | RichText>): rt is RichText =>
  !!rt && (has(rt, "html") || has(rt, "markdown") || has(rt, "text"));

export const isEmpty = (rt: Maybe<RichText>) => !hasValue(rt);

export const toTextLike = (text: Maybe<RichText>) =>
  text?.markdown || text?.html || "";

export const toMarkdown = (text: Maybe<RichText>) =>
  text?.markdown || when(text?.html, md.toMarkdown) || "";

export const toHtml = (text: Maybe<RichText>) =>
  text?.html || when(text?.markdown, md.toHtml) || "";

export function append(a: Maybe<RichText>, b: RichText): RichText;
export function append(a: RichText, b: Maybe<RichText>): RichText;
export function append(a: Maybe<RichText>, b: Maybe<RichText>): Maybe<RichText>;
export function append(
  a: Maybe<RichText>,
  b: Maybe<RichText>
): Maybe<RichText> {
  if (!a) return b;
  if (!b) return a;

  if (a.html || b.html) {
    return {
      html: `${toHtml(a)}${toHtml(b)}`,
    };
  }

  if (a.markdown || b.markdown) {
    return {
      markdown: `${toMarkdown(a)}${toMarkdown(b)}`,
    };
  }

  return {
    text: `${a.text || ""}${b.text || ""}`,
  };
}

export const extractMentions = (rt: Maybe<RichText>) => {
  const text = toHtml(rt);
  // it returns an empty array if there are no links
  const links = {} as Record<string, Maybe<RelationRef>>;

  // Regex to extract mention ids inside data-mention-id attributes and the text inside the a tag
  const idRegex = /<a[^>]*data-mention-id="([^"]+)"[^>]*>([^<]+)<\/a>/g;

  let match;

  // Extract markdown links
  match = idRegex.exec(text);
  while (match != null) {
    const id = match[1];
    links[id] = {
      id,
      name: match[2],
    };
    match = idRegex.exec(text);
  }

  return maybeValues(links);
};

// Trims trailing mentions from a string such as "@mention" or "@mention." but not from emails like name@example.com
export function trimTrailingMentions(input: string): string {
  // Regular expression to match mentions at the end of a string (@mention or @mention.)
  const mentionRegex = /(?:@\w+)(?:\.$|$)/;

  // Split the input by spaces to check each word
  const words = input.split(" ");

  // Check the last word to see if it's a mention (and not an email)
  const lastWord = words[words.length - 1];

  // Use regex to see if it matches a mention pattern and not an email pattern
  if (
    mentionRegex.test(lastWord) &&
    !lastWord.includes("@") &&
    !lastWord.includes(".com")
  ) {
    words.pop(); // Remove the last word if it matches a mention
  }

  // Rejoin the string and return
  return words.join(" ");
}
